import M$plugin$Topographicprofile from '/home/carmenmarquez/Escritorio/apiol/API-CNIG/api-ign-js/src/plugins/topographicprofile/src/facade/js/topographicprofile';
import M$control$Topographicprofilecontrol from '/home/carmenmarquez/Escritorio/apiol/API-CNIG/api-ign-js/src/plugins/topographicprofile/src/facade/js/topographicprofilecontrol';
import M$impl$control$TopographicprofileControl from '/home/carmenmarquez/Escritorio/apiol/API-CNIG/api-ign-js/src/plugins/topographicprofile/src/impl/ol/js/topographicprofilecontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Topographicprofile = M$plugin$Topographicprofile;
window.M.control.Topographicprofilecontrol = M$control$Topographicprofilecontrol;
window.M.impl.control.TopographicprofileControl = M$impl$control$TopographicprofileControl;
